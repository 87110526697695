.dark-mode {
    background-color: #212121;
    color: #e0e0e0;

    a {
        color: #64b5f6;
    }
    a:hover {
        color: #2196f3;
    }

    input {
        border-color: #707070;
        background-color: #313131;
        color: #e0e0e0;
    }

    .period-selector {
        border-bottom-color: #555;
    }

    .period-button {
        color: #e0e0e0;
    }

    .period-button.selected {
        border-bottom-color: #4da6ff;
        color: #4da6ff;
    }
}

* {
    font-family: 'Darker Grotesque', sans-serif;
}

a {
    color: #5070c0;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}

input {
    font-size: large;
    padding: 0.5em;
    border-radius: 0.5em;
}

.period-selector {
    display: flex;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.period-button {
    padding: 10px 20px;
    border: none;
    background: none;
    cursor: pointer;
    color: #333;
    font-weight: normal;
}

.period-button.selected {
    border-bottom: 2px solid #007bff;
    color: #007bff;
    font-weight: bold;
}
